import * as React from "react"
import styled from "@emotion/styled"
import { Link, navigate } from "gatsby"
import { Flex, Box } from "theme-ui"
import useDetectScroll from "@smakss/react-scroll-direction"

import loadable from "@loadable/component"

import { AccountContext } from "../../context/AccountContext"
import { InterfaceContext } from "../../context/InterfaceContext"
import useWindowSize from "../../hooks/useWindowSize"
import useLockedBody from "../../hooks/v2/useLockedBody"
import useLockedBodyMobile from "../../hooks/v2/useLockedBodyMobile"
import getDisplayItems from "../../utils/get-display-items"
import { isDesktopWindow, isMobileWindow } from "../../utils/render-util"
import MenuWrapper from "../new-ui/product-menu"
import LoadingSpinner from "../ui/LoadingSpinner"
import { Cart } from "../v2/cart/Cart"
import { CartBag } from "./elements"
import LoginDrawer from "./LoginDrawer"
import RegionDrawer from "./RegionDrawer"
import { trackSearchMenuClicked } from "../../services/analytics"
import useDrawerHook from "../../hooks/useDrawerHook"
import NoStoreModal from "../new-ui/modal/no-store-modal"
import { MobileNavigation } from "../v2/MobileNavigation"
import { SearchDrawer } from "../v2/SearchDrawer"

import BagIcon from "../../assets/svg/bag-icon.svg"
import { useStore } from "../../context/NewStoreContext"

import qs from "query-string"

const DesktopMenu = loadable(() => import("../new-ui/main-menu/desktop"))

const Wishlist = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
  line-height: 0.6;

  svg {
    width: 13px;
    height: 16px;
  }

  span {
    font-size: 14px;
    margin-top: 2px;
    margin-left: 1px;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    line-height: inherit;
  }

  .heartIcon {
    height: 17px;
    width: auto;
  }
`

const Account = styled.div`
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  ${(props) =>
    props.hide &&
    `
  span {
    visibility: hidden;
  }
  `}

  .mobile {
    display: inline-block;
    font-size: 16px;
    position: relative;
    top: 0.5px;
  }
  .desktop {
    display: none;
  }

  .userIcon {
    position: relative;
    top: 2px;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    margin-left: 1rem;
    ${(props) => props.regionVisible && `flex: 1`};

    .desktop {
      display: inline-block;
    }
    .mobile {
      display: none;
    }
  }
`

const Region = styled.div`
  display: none;
  cursor: pointer;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: block;
    margin-left: 1rem;
  }
`

const HamburgerButton = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 1px;
    background-color: ${(props) => props.theme.colors.grayscale[700]};
    position: absolute;
    transition: transform 0.2s;
  }

  &::before {
    top: ${(props) => (props.isActive ? "9px" : "5px")};
    transform: ${(props) => props.isActive && "rotate(45deg)"};
  }

  &::after {
    bottom: ${(props) => (props.isActive ? "10px" : "5px")};
    transform: ${(props) => props.isActive && "rotate(-45deg)"};
  }
`

const Header = ({
  navbarInWhite,
  withPromoElement,
  isHomepage,
  promotionalElement,
  isProductsPage,
  openCart,
}) => {
  const {
    toggleRegionOpen,
    hideDrawers,
    isLoginOpen,
    toggleCart,
    isCartOpen,
    isRegionOpen,
    wishlistCount,
    showCart,
  } = React.useContext(InterfaceContext)

  const { loggedIn, logOut, name } = React.useContext(AccountContext)
  const [isAccount, setIsAccount] = React.useState(false)
  const windowSize = useWindowSize()
  const { scrollDir, scrollPosition } = useDetectScroll()

  const [headerOptions, setHeaderOptions] = React.useState({
    headerHasBackground: false,
    offcanvasIsOpen: false,
  })
  const [menuVisible, setMenuVisible] = React.useState(false)
  const { cart, setRegion, noStoreModal, setNoStoreModal } = useStore()
  const [locked, setLocked] = useLockedBody(false)
  const [lockedMobile, setLockedMobile] = useLockedBodyMobile(false)

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      let account = window.location.pathname.includes("account")
      setIsAccount(account)
    }

    const { share, paymentRefused } = qs.parse(window.location.search)

    if ((share && typeof share === "string" && !isCartOpen) || openCart) {
      toggleCart()
    } else if (isCartOpen && !cart?.items?.length) {
      toggleCart()
    }

    if (paymentRefused && !isCartOpen) {
      toggleCart()
    }
  }, [])

  React.useEffect(() => {
    if (isRegionOpen) {
      setHeaderOptions({
        ...headerOptions,
        headerHasBackground: true,
      })
    }
  }, [isRegionOpen])

  const handleSetCountry = (country) => {
    setRegion(country.region_id, country.country_code)
    toggleRegionOpen()
    isMobileWindow(windowSize.width) ? setLockedMobile(false) : setLocked(false)
    setHeaderOptions({ ...headerOptions, offcanvasIsOpen: false })
  }

  const [cartLoading, setCartLoading] = React.useState(true)

  const itemsInCart = React.useMemo(() => {
    const i = getDisplayItems(cart)
    return i.reduce((total, item) => total + item.quantity, 0)
  }, [cart])

  const { drawerContent, setDrawerContent, setDrawerVisible } = useDrawerHook({
    config: { asModal: true },
  })

  React.useEffect(() => {
    if (cart?.id != undefined && !noStoreModal) {
      setCartLoading(false)
    } else if (noStoreModal) {
      setDrawerContent(
        <NoStoreModal
          afterSubmit={() => {
            setDrawerVisible(false)
            setNoStoreModal(false)
          }}
        />
      )
      setDrawerVisible(true)
    }
  }, [cart, noStoreModal])

  const homeUrl = "/"

  const whiteNavbar =
    isHomepage &&
    navbarInWhite &&
    !isCartOpen &&
    !isRegionOpen &&
    withPromoElement

  React.useEffect(() => {
    if (isCartOpen) {
      setLocked(true)
    } else {
      setLocked(false)
    }
  }, [isCartOpen])

  return (
    <>
      {drawerContent}
      <Box
        onMouseOver={() => {
          if (isLoginOpen) {
            hideDrawers()
          }
        }}
        onClick={() => {
          if (isLoginOpen || isCartOpen || isRegionOpen) {
            hideDrawers()
          }
        }}
        sx={{
          width: "100%",
          height: "100%",
          opacity: isLoginOpen || isCartOpen || isRegionOpen ? 1 : 0,
          visibility:
            isLoginOpen || isCartOpen || isRegionOpen ? "visible" : "hidden",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: "dismissCatch",
          backdropFilter: "blur(15px)",
          transition: "opacity .9s, visibility .9s",
        }}
      />
      <Flex
        onMouseOver={() =>
          isDesktopWindow(windowSize.width) &&
          setHeaderOptions({
            ...headerOptions,
            headerHasBackground: true,
          })
        }
        onMouseOut={() =>
          isDesktopWindow(windowSize.width) &&
          setHeaderOptions({
            ...headerOptions,
            headerHasBackground: false,
          })
        }
        sx={{
          position: "sticky",
          top: "0",
          justifyContent: "space-between",
          width: "100%",
          height: 13,
          backgroundColor:
            headerOptions.headerHasBackground ||
            menuVisible ||
            (isHomepage && !withPromoElement) ||
            isProductsPage ||
            (scrollDir === "down" && scrollPosition.top > 100) ||
            (scrollDir === "up" && scrollPosition.top > 1)
              ? "grayscale.100"
              : ["grayscale.100", "transparent"],
          zIndex: "header",
          paddingInline: 3,
          transform:
            scrollDir === "down" && scrollPosition.top > 52
              ? "translateY(-100%)"
              : "translateY(0)",
          transition: "transform .25s, background-color .25s",
        }}
      >
        <Flex
          sx={{
            height: "100%",
            alignItems: "center",
            fontSize: "sm",
            color:
              (whiteNavbar &&
                !headerOptions.headerHasBackground &&
                !isMobileWindow(windowSize.width) &&
                scrollPosition.top < 1 &&
                !menuVisible) ||
              (whiteNavbar &&
                scrollDir === "down" &&
                scrollPosition.top < 100 &&
                !menuVisible)
                ? "grayscale.white"
                : "grayscale.700",
          }}
        >
          <Box sx={{ marginInlineEnd: 9, svg: { display: "block" } }}>
            <Link to={homeUrl || "/"}>
              {(whiteNavbar &&
                !headerOptions.headerHasBackground &&
                !isMobileWindow(windowSize.width) &&
                scrollPosition.top < 1 &&
                !menuVisible) ||
              (whiteNavbar &&
                !isMobileWindow(windowSize.width) &&
                scrollDir === "down" &&
                scrollPosition.top < 100 &&
                !menuVisible) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="93"
                  fill="none"
                  viewBox="0 0 93 20"
                >
                  <path
                    fill="#fff"
                    d="M0 0v6.26h4.19v13.13h7.37V6.26h4.2V0H0ZM18.28 0v19.39H32v-5.42h-6.64v-1.81h5.73V7.12h-5.73v-1.7h6.42V0h-13.5ZM45.79 0l-4.14 8.16h-.05V0h-7.08v19.39h7.08v-8.38h.05l4.76 8.38h7.74L47.94 9.4l5.4-9.4h-7.55ZM55.59 0v19.39h13.47v-6.36h-6.11V0h-7.36ZM81.38 5.41l1.46 5.74h-3l1.54-5.74ZM77.08 0l-6.5 19.38h7.11l.88-3.32h5.57l.85 3.32h7.39L85.8 0h-8.72Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="93"
                  fill="none"
                  viewBox="0 0 93 20"
                >
                  <path
                    fill="#231F20"
                    d="M0 0v6.26h4.19v13.13h7.37V6.26h4.2V0H0ZM18.28 0v19.39H32v-5.42h-6.64v-1.81h5.73V7.12h-5.73v-1.7h6.42V0h-13.5ZM45.79 0l-4.14 8.16h-.05V0h-7.08v19.39h7.08v-8.38h.05l4.76 8.38h7.74L47.94 9.4l5.4-9.4h-7.55ZM55.59 0v19.39h13.47v-6.36h-6.11V0h-7.36ZM81.38 5.41l1.46 5.74h-3l1.54-5.74ZM77.08 0l-6.5 19.38h7.11l.88-3.32h5.57l.85 3.32h7.39L85.8 0h-8.72Z"
                  />
                </svg>
              )}
            </Link>
          </Box>
          {isDesktopWindow(windowSize.width) && (
            <DesktopMenu
              setMenuVisible={setMenuVisible}
              sx={{ marginInlineStart: [2, 4] }}
            />
          )}
        </Flex>
        <Flex
          sx={{
            height: "100%",
            alignItems: "center",
            fontSize: "sm",
            color:
              (whiteNavbar &&
                !headerOptions.headerHasBackground &&
                !isMobileWindow(windowSize.width) &&
                scrollPosition.top < 1) ||
              (whiteNavbar && scrollDir === "down" && scrollPosition.top < 100)
                ? "grayscale.white"
                : "grayscale.700",
          }}
        >
          {cartLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {isDesktopWindow(windowSize.width) && (
                <SearchDrawer>
                  <Box
                    onClick={() => trackSearchMenuClicked()}
                    sx={{ marginInlineStart: 4, cursor: "pointer" }}
                  >
                    Search
                  </Box>
                </SearchDrawer>
              )}
              <Region onClick={toggleRegionOpen} visible={isRegionOpen}>
                {cart?.shipping_address?.country_code?.toUpperCase()}
                &#8201;/&#8201;
                {cart?.region?.currency_code?.toUpperCase()}
              </Region>
              <Account
                regionVisible={isRegionOpen}
                onClick={() => {
                  hideDrawers()
                  if (loggedIn) {
                    if (isAccount) {
                      logOut().then(() => {
                        navigate("/")
                      })
                    } else {
                      navigate("/account/welcome-back")
                    }
                  } else {
                    navigate("/account/sign-in")
                  }
                }}
              >
                <span className="desktop">
                  {loggedIn ? (isAccount ? "Log out" : name) : "Login"}
                </span>
              </Account>
              {!isMobileWindow(windowSize.width) && (
                <Wishlist onClick={() => navigate("/wishlist")}>
                  Wishlist
                  {wishlistCount > 0 && (
                    <span>(&#8201;{wishlistCount}&#8201;)</span>
                  )}
                </Wishlist>
              )}
              <CartBag
                onClick={showCart}
                wishlistCount={
                  isMobileWindow(windowSize.width) && wishlistCount > 0
                    ? true
                    : false
                }
              >
                {!isMobileWindow(windowSize.width) ? "Cart" : <BagIcon />}{" "}
                {itemsInCart > 0 && <span>(&#8201;{itemsInCart}&#8201;)</span>}
              </CartBag>

              {isMobileWindow(windowSize.width) && (
                <Flex
                  sx={{
                    width: 4,
                    marginInlineStart: 4,
                    marginInlineEnd: 2,
                  }}
                >
                  <HamburgerButton
                    onClick={() => {
                      headerOptions.offcanvasIsOpen && isRegionOpen
                        ? toggleRegionOpen()
                        : null

                      setHeaderOptions({
                        ...headerOptions,
                        headerHasBackground: !headerOptions.offcanvasIsOpen,
                        offcanvasIsOpen: !headerOptions.offcanvasIsOpen,
                      })

                      setLockedMobile((prevValue) => !prevValue)

                      isCartOpen && toggleCart()
                    }}
                    isActive={headerOptions.offcanvasIsOpen}
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Flex>
      <MobileNavigation
        promotionalElement={promotionalElement}
        isOpen={headerOptions.offcanvasIsOpen}
        headerOptions={headerOptions}
        setHeaderOptions={setHeaderOptions}
        setLockedMobile={setLockedMobile}
        setLocked={setLocked}
      />
      <MenuWrapper />
      <LoginDrawer visible={isLoginOpen} hideDrawers={hideDrawers} />
      {cart?.id ? <Cart isCartOpen={isCartOpen} /> : null}
      <RegionDrawer
        visible={isRegionOpen}
        onSetCountry={handleSetCountry}
        toggleRegionOpen={toggleRegionOpen}
        promotionalVisible={withPromoElement}
      />
    </>
  )
}

export default Header
